// @ts-check
if (document.getElementById("searchPage")) {
    const app = Vue.createApp({
        data() {
            return {
                openInMobile: false,
                searchType: "",
                searchStatus: 0,
                mountStatus: 0,
                queryText: "",
                query: "",
                lastPage: true,
                offset: 0,
                lang: window.displayLanguage,
                pageId: 0,
                statusFilter: [],
                typeFilter: [],
                categoryFilter: [],
                languageFilter: [],
                icsFilter: [],
                publisherFilter: [],

                statusFilterOpen: false,
                typeFilterOpen: false,
                categoryFilterOpen: false,
                languageFilterOpen: false,
                icsFilterOpen: false,
                publisherFilterOpen: false,

                searcharea: "all",
                showOnlyInSubscription: false,
                trackId: "",

                resultCount: 0,
                results: [],
                resultCountAdjusted: false,
                percentage: 0,
                productLists: [], // Used for add/remove product to list
                productListsProductId: null,

                allIcsCodes: []
            }
        },
        mounted: function () {
            var that = this;
            let parsedHash = window.location.hash.substr(1).split('&').reduce(function (res, item) {
                var parts = item.split('=');
                res[parts[0]] = parts[1];
                return res;
            }, {});

            that.query = decodeURIComponent(parsedHash.q);
            if (that.query == undefined) that.query = "";
            this.showOnlyInSubscription = decodeURIComponent(parsedHash.sub);
            this.statusFilter = filterFromHash(parsedHash.s);
            this.typeFilter = filterFromHash(parsedHash.t);
            this.categoryFilter = filterFromHash(parsedHash.c);
            this.languageFilter = filterFromHash(parsedHash.l);
            this.publisherFilter = filterFromHash(parsedHash.p);
            this.icsFilter = filterFromHash(parsedHash.i);

            window.search = this.search;
            window.addEventListener("hashchange", function () { window.search(); }, false);
            if (parsedHash) {
                this.search(false, true);
            }
            $('[data-toggle="tooltip"]').tooltip();

            $(window).scroll($.throttle(500, function () {
                if ($(window).scrollTop() >= $(document).height() - $(window).height() - 1500) {
                    if (!that.lastPage) this.search(true);
                }
            }));
            this.mountStatus = 1;
        },
        watch: {
            showOnlyInSubscription: { handler: 'updateHash' },
            statusFilter: { deep: true, handler: 'updateHash' },
            typeFilter: { deep: true, handler: 'updateHash' },
            categoryFilter: { deep: true, handler: 'updateHash' },
            languageFilter: { deep: true, handler: 'updateHash' },
            publisherFilter: { deep: true, handler: 'updateHash' },
            icsFilter: { deep: true, handler: 'updateHash' }
        },
        methods: {
            updateHash: function () {
                if (this.query === "undefined") this.query = "";
                if (this.showOnlyInSubscription == undefined) this.showOnlyInSubscription = false;

                var hash = "q=" + this.query;
                hash += "&sub=" + encodeURIComponent(this.showOnlyInSubscription);
                hash += "&s=" + encodeURIComponent(activeFilter(this.statusFilter));
                hash += "&t=" + encodeURIComponent(activeFilter(this.typeFilter));
                hash += "&c=" + encodeURIComponent(activeFilter(this.categoryFilter));
                hash += "&l=" + encodeURIComponent(activeFilter(this.languageFilter));
                hash += "&i=" + encodeURIComponent(activeFilter(this.icsFilter));

                hash += "&p=" + encodeURIComponent(activeFilter(this.publisherFilter));

                if (window.location.hash !== hash) {
                    window.location.replace('#' + hash.replace(/^#/, ''));
                }
            },
            search: function (append, first) {
                var autocomplete = $("#SearchKeywords");
                if (autocomplete.hasClass('ui-autocomplete-input')) {
                    autocomplete.autocomplete("close");
                }
                if (this.searchStatus == 1) return;
                var that = this
                this.searchStatus = 1;
                this.queryText = that.query;

                if (append === true) {
                    this.offset = this.results.length;
                } else {
                    this.offset = 0;
                }

                $.post("/api/search/getmoreresults", {
                    query: this.query,
                    offset: this.offset,
                    lang: this.lang,
                    pageId: this.pageId,
                    types: activeFilter(this.typeFilter),
                    category: activeFilter(this.categoryFilter),
                    languages: activeFilter(this.languageFilter),
                    ics: activeFilter(this.icsFilter),
                    searcharea: this.searcharea,
                    showOnlyInSubscription: this.showOnlyInSubscription,
                    status: activeFilter(this.statusFilter),
                    publisher: activeFilter(this.publisherFilter),
                    first: first,
                    trackId: this.trackId
                }).done(function (result) {
                    if (result.data !== null && result.data !== undefined) {
                        that.showOnlyInSubscription = result.data.showOnlyInSubscription;
                        that.statusFilter = result.data.statusFilter;
                        that.typeFilter = result.data.typeFilter;
                        that.publisherFilter = result.data.publisherFilter;
                        that.languageFilter = result.data.languageFilter;
                        that.icsFilter = result.data.icsFilter;
                        that.resultCount = result.data.resultCount;
                        that.lastPage = result.data.lastPage;
                        if (append === true) {
                            Array.prototype.push.apply(that.results, result.data.results);
                        } else {
                            that.results = result.data.results;
                        }
                        that.percentage = Math.ceil((that.results.length / that.resultCount) * 100);

                        that.resultCountAdjusted = result.data.resultCountAdjusted;

                        if (first) {
                            that.statusFilterOpen = that.statusFilter.filter(function (item) { return item.isActive; }).length > 0;
                            that.typeFilterOpen = that.typeFilter.filter(function (item) { return item.isActive; }).length > 0;
                            that.publisherFilterOpen = that.publisherFilter.filter(function (item) { return item.isActive; }).length > 0;
                            that.languageFilterOpen = that.languageFilter.filter(function (item) { return item.isActive; }).length > 0;
                            that.icsFilterOpen = that.icsFilter.filter(function (item) { return item.isActive; }).length > 0;
                        }

                        var selectedTypes = that.typeFilter.filter(function (item) { return item.isActive; });
                        if (selectedTypes.length === 0) {
                            that.searchType = "all";
                        } else
                            if (selectedTypes.length === 1) {
                                that.searchType = selectedTypes[0].filterName;
                            } else {
                                that.searchType = null;
                            }
                    }
                    that.searchStatus = 2;
                });
            },
            addToCart: function (item) {
                AddToCartClick(item.codeForCart, item.standardIsInvalid, true, false);
            },
            sendReaderEvent: function (event, result) {
                window.dataLayer.push({
                    'event': event,
                    'name': result.designation,
                    'id': result.productId
                });
            },
            searchTypeChange: function (e) {
                this.typeFilter = this.typeFilter.map(function (item) { item.isActive = (item.filterName == e.target.value); return item; });
                this.typeFilterOpen = this.typeFilter.filter(function (item) { return item.isActive; }).length > 0;

            },
            clearFilter: function () {
                this.statusFilter = clearFilter(this.statusFilter);
                this.typeFilter = clearFilter(this.typeFilter);
                this.categoryFilter = clearFilter(this.categoryFilter);
                this.languageFilter = clearFilter(this.languageFilter);
                this.publisherFilter = clearFilter(this.publisherFilter);
                this.icsFilter = clearFilter(this.icsFilter);
            },
            toggleFavorite: function (item) {
                AddRemoveFavourite(item.productId, item.isFavourite);//TODO: should only allow change to happen if following call succeeds
            },
            toggleProductInList: function (list) {
                $.getJSON("/api/subscription/list/addremovelistproduct", { subscriptionId: getCookie("subscriptionId"), listId: list.value, productId: this.productListsProductId, add: list.selected })
            },
            showAllIcsCodes: function () {
                var that = this;
                if (that.allIcsCodes.length == 0) {
                    $.getJSON("/api/search/geticscodes", { language: that.lang }).done(function (result) {
                        that.allIcsCodes = result.data;
                    });
                }
                $('#allIcsCodes').dialog({
                    modal: true,
                    transitionMask: false,
                    position: { my: "right+50 top+10", at: "bottom", of: window },
                    title: $('#allIcsCodes').data("title"),
                    dialogClass: "icsCodesDialog",
                    open: function () {
                        setTimeout(function () {

                            for (var First in that.allIcsCodes) {
                                var FirstLevel = that.allIcsCodes[First]
                                var filterIndex = that.icsFilter.findIndex(item => item.filterName == FirstLevel.code);
                                if (filterIndex != -1) {
                                    FirstLevel.selected = that.icsFilter[filterIndex].isActive;
                                }
                                for (var Second in FirstLevel.subCodes) {
                                    var SecondLevel = FirstLevel.subCodes[Second];
                                    filterIndex = that.icsFilter.findIndex(item => item.filterName == SecondLevel.code);
                                    if (filterIndex == -1) {
                                        SecondLevel.selected = false;
                                    }
                                    else
                                        SecondLevel.selected = true;

                                    for (var Third in SecondLevel.subCodes) {
                                        var ThirdLevel = SecondLevel.subCodes[Third];
                                        filterIndex = that.icsFilter.findIndex(item => item.filterName == ThirdLevel.code);
                                        if (filterIndex == -1) {
                                            ThirdLevel.selected = false;
                                        }
                                        else
                                            ThirdLevel.selected = true;

                                    }
                                }
                            }
                        }, 100);
                    }
                    ,
                    buttons: {
                        "OK": function () {
                            // Gather all selected codes of all levels
                            var list = [];
                            for (var lvl0 in that.allIcsCodes) {
                                var level0 = that.allIcsCodes[lvl0]
                                if (level0.selected) list.push(level0);
                                for (var l1 in level0.subCodes) {
                                    var level1 = level0.subCodes[l1];
                                    if (level1.selected) list.push(level1);
                                    for (var l2 in level1.subCodes) {
                                        var level2 = level1.subCodes[l2];
                                        if (level2.selected) list.push(level2);
                                    }
                                }
                            }
                            clearFilter(that.icsFilter);

                            for (var i in list) {
                                if (that.icsFilter.filter(function (item) { item.filterName == list[i].code }).length == 0) {
                                    that.icsFilter.push({ filterName: list[i].code, isActive: true, title: list[i].title });
                                }
                            }

                            $(this).dialog("close");
                        }
                    }
                });
            },
            showListDialog: function (item) {
                var that = this;
                var subscriptionId = getCookie("subscriptionId");
                this.productListsProductId = item.productId;
                $.getJSON("/api/subscription/list/getproductlists", { subscriptionId: subscriptionId, productId: item.productId })
                    .done(function (result) {
                        if (result.data !== null && result.data !== undefined) {
                            that.productLists = result.data;
                            $('#addremovelistproduct').dialog({
                                modal: true,
                                transitionMask: false,
                                position: { my: "right+50 top+10", at: "bottom", of: window },
                                title: "Välj lista",
                                buttons: {
                                    "OK": function () {
                                        $(this).dialog("close");
                                    }
                                }
                            });
                        };
                    });
            },
            PopulateOfferModalInSearch: function (item) {
                PopulateOfferModal(item.title, item.productId, item.edition, item.langCodeList);
            }
        }
    });
    app.config.compilerOptions.comments = true; // Necessary to allow knockout based shoppingcart
    app.mount("#searchPage");
}
function filterFromHash(hash) {
    if (hash)
        return decodeURIComponent(hash).split(',').map(function (filterName) { return { isActive: true, filterName: filterName } })
    else
        return [];
}

function clearFilter(filter) {
    return filter.map(function (item) { item.isActive = false; return item; });
}

function activeFilter(filter) {
    if (filter) {
        return filter.filter(function (item) { return item.isActive; }).map(function (item) { return item.filterName; });
    } else {
        return null;
    }
}

$(function () {
    // Quicksearchblock when not on search page
    $("#search-area[data-searchpage] button, #hero-block button").on("click", function (e) {
        quickSearch();
    });

    $("#search-area[data-searchpage] #SearchKeywords, #hero-block #SearchKeywords").on("keypress", function (e) {
        if (e.which == 13) {
            quickSearch();
        }
    });
});

function quickSearch() {
    var searchPage = $("#search-area").data("searchpage");
    if (searchPage == undefined) {
        searchPage = $("#hero-block").data("searchpage");
    }
    document.location.href = searchPage + "#q=" + $("#SearchKeywords").val() + "&t=" + $("#searchTypeSelector").val();
}

/* Autocomplete */
$(function () {
    $("#SearchKeywords").autocomplete({
        source: function (request, response) {
            $.getJSON("/api/search/autocomplete", { term: request.term, lang: window.displayLanguage, searcharea: $("#searchTypeSelector").val() }, function (result) { return response(result.data); })
        },
        focus: function (event, item) {
            event.preventDefault();
        },
        delay: 500,
        minLength: 2,
        appendTo: "#autocompletediv",
        select: function (event, item) {
            window.location = item.item.value;
            event.preventDefault();
        }
    });
});