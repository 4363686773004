function ShoppingCartLine(item) {
    var that = this;

    that.code = ko.observable(item.code);
    that.displayName = ko.observable(item.displayName);
    that.unitPrice = ko.observable(item.unitPrice);
    that.discountedUnitPrice = ko.observable(item.discountedUnitPrice);
    that.unitPriceEur = ko.observable(item.unitPriceEur);
    that.discountedUnitPriceEur = ko.observable(item.discountedUnitPriceEur);
    that.quantity = ko.observable(item.quantity);
    that.category = ko.observable(item.category);
    that.subCategory = ko.observable(item.subCategory);
    that.designation = ko.observable(item.designation);
    that.iconUrl = ko.observable(item.iconUrl);
    that.productUrl = ko.observable(item.productUrl);
    that.includedItems = ko.observableArray(item.includedItems);
    that.languages = ko.observable(item.languages);
    that.langCodeList = ko.observable(item.langCodeList);
    that.shipping = ko.observable(item.shipping);
    that.totalPrice = ko.observable(item.totalPrice);
    that.totalPriceEur = ko.observable(item.totalPriceEur);
    that.translatedSubCat = ko.observable(false);
    that.approvalDate = ko.observable(item.approvalDate);
    that.physicalVariant = ko.observable(item.physicalVariant);
    that.linkToAddendum = ko.observable(item.linkToAddendum);

    that.showWarning = ko.observable(item.showWarning);

    var words = ["TILLVERKADE HANDBÖCKER", "INKÖPTA HANDBÖCKER", "HANDBOOKS MANUFACTURED", "HANDBOOKS PURCHASED"];

    if (item.subCategory) {
        if (words.indexOf(item.subCategory.toUpperCase()) > -1) {
            that.translatedSubCat(true);
        }
    }
};

function ShoppingCartArrayItem(cartItem) {
    var that = this;

    that.orderId = ko.observable(cartItem.orderId);

    that.sum = ko.observable(cartItem.sum);
    that.sumEur = ko.observable(cartItem.sumEur);

    that.shippingTotal = ko.observable(cartItem.shippingTotal);
    that.shippingTotalEur = ko.observable(cartItem.shippingTotalEur);

    that.vat = ko.observable(cartItem.vat);
    that.vatEur = ko.observable(cartItem.vatEur);

    that.total = ko.observable(cartItem.total);
    that.totalEur = ko.observable(cartItem.totalEur);

    that.orderDiscount = ko.observable(cartItem.orderDiscount);
    that.orderDiscountEur = ko.observable(cartItem.orderDiscountEur);

    that.checkoutPageUrl = ko.observable(cartItem.checkoutPageUrl);

    that.couponCode = ko.observable(cartItem.couponCode);
    that.couponText = ko.observable(cartItem.couponText);

    that.cartItems = ko.observableArray();
    that.cartItems.removeAll();

    var itemCount = 0;
    if (cartItem.cartItems !== null) {
        $.each(cartItem.cartItems, function () {
            that.cartItems.push(new ShoppingCartLine(this));
            itemCount += this.quantity;
        });
    }

    that.enableCheckout = ko.observable(itemCount > 0);
}

function ShoppingCart() {

    var that = this;

    that.shoppingCartArray = ko.observableArray([]);
    that.subscriptionIteamAdded = ko.observableArray([]);
    that.paymentType = ko.observable();
    that.acceptTerms = ko.observable(false);
    that.enableBuyButton = ko.observable(null);
    that.availableQuantities = ko.observableArray();
    that.enableCheckout = ko.observable(false);
    that.subscriptionComment = ko.observable('');
    that.invoiceRef = ko.observable('');
    that.showEur = ko.observable(false);
    that.couponError = ko.observable('');
    that.customPackagesInfo = ko.observable('');
    that.customPackagesCount = ko.observable(0);
    that.customPackagesBought = ko.observable(0);
    that.subscriptionCheckoutThanks = ko.observable(false);
    that.hideAddSubscription = ko.observable(true);
    that.addToSubscriptionButtonDisabled = ko.observable(false);

    that.refresh = function () {
        console.log("refresh()");
        $.getJSON("/api/cart/getcart", { language: window.displayLanguage })
            .done(function (result) {
                if (result.data !== null && result.data !== undefined) {

                    var itemCount = 0;
                    if (result.data[0].cartItems !== null) {
                        itemCount = result.data[0].cartItems.length;
                    }
                    if (itemCount != 0) {
                        $(".navbar-header-cart").text(itemCount).addClass('filled-cart').removeClass('empty-cart');
                        $("#shopping-cart-button").text(itemCount).css("background-image", "url(/Static/images/SIS_Ikon_Shopping_has_items.svg)");
                    } else {
                        $(".navbar-header-cart").text("").addClass('empty-cart').removeClass('filled-cart');
                        $("#shopping-cart-button").text("").css("background-image", "url(/Static/images/SIS_Ikon_Shopping.svg)");
                    }
                    that.showEur = ko.observable(result.data[0].userCurrency === "EUR");
                    that.invoiceRef = ko.observable(result.data[0].invoiceReference);

                    that.shoppingCartArray.removeAll();

                    that.hideAddSubscription(true);
                    if (result.data[1].hideAddSubscription === false) {
                        that.hideAddSubscription(false);
                    }

                    $.each(result.data, function () {
                        var shoppingCartArrayItem = new ShoppingCartArrayItem(this);
                        that.shoppingCartArray.push(shoppingCartArrayItem);
                    });

                    // Disable buy button if there are any items in the cart that have a warning
                    if (that.shoppingCartArray()[2].cartItems !== undefined &&
                        that.shoppingCartArray()[2].cartItems().length > 0 &&
                        that.shoppingCartArray()[2].cartItems().some(item => item.showWarning())) {
                        that.addToSubscriptionButtonDisabled(true);
                    }

                    // And other way around, if there are no warning anymore, enable the button
                    if (that.shoppingCartArray()[2].cartItems !== undefined &&
                        that.shoppingCartArray()[2].cartItems().length > 0 &&
                        !that.shoppingCartArray()[2].cartItems().some(item => item.showWarning())) {
                        that.addToSubscriptionButtonDisabled(false);
                    }


                    that.updateEnableBuyButton();
                    that.customPackagesInfo(result.data[2].customPackagesInfo);
                    that.customPackagesCount(result.data[2].customPackagesCount);
                    that.customPackagesBought(result.data[2].customPackagesBought);
                }
            });
    };

    that.addToCart = function (code, includestandardamendmentscorrections, addSingleCopy) {
        $.getJSON("/api/cart/addtocart", { code: code, includestandardamendmentscorrections: includestandardamendmentscorrections, addSingleCopy: addSingleCopy })
            .done(function (result) {

                if (result.data.warningMessage !== undefined) {
                    alert(result.data.warningMessage);
                    return;
                }

                var countquest = new Countquest();

                var products = [];
                var origin = "Produktsida";
                if (document.getElementById("searchinfinitescroll")) {
                    origin = "Sökresultat";
                }

                $.each(result.data, function (index, item) {
                    products.push({
                        'name': item.designation != null ? item.designation : item.displayName,
                        'code': item.code,
                        'price': (item.discountedUnitPrice == null ? item.unitPrice : item.discountedUnitPrice),
                        'category': item.category,
                        'variant': GetDeliveryTypeByCode(item.code),
                        'quantity': item.quantity
                    });
                });

                countquest.addToCart(products, origin);

                that.refresh();
            });
    };

    that.changeCartItem = function (item) {
        $.getJSON("/api/cart/changecartitem", { code: item.code(), quantity: item.quantity() })
            .done(function (result) {
                that.refresh();
            });
    };

    that.removeFromCart = function (item) {
        if (!confirm('Är du säker på att du vill ta bort varan?')) {
            return;
        }

        var countquest = new Countquest();
        countquest.removeFromCart(
            (item.designation() != null ? item.designation() : item.displayName()),
            item.code(),
            (item.discountedUnitPrice() == null ? item.unitPrice() : item.discountedUnitPrice()),
            item.category(),
            GetDeliveryTypeByCode(item.code()),
            item.quantity()
        );

        $.getJSON("/api/cart/changecartitem", { code: item.code(), quantity: 0 })
            .done(function (result) {
                that.refresh();
            });
    };

    that.addCouponCode = function () {
        var code = $("#coupon-code").val();
        if (code != null) {
            $.getJSON("/api/checkout/addcouponcode", { couponCode: code })
                .done(function (result) {
                    if (result.data === 'ok') {
                        that.refresh();
                    }
                    else {
                        that.couponError(result.data);
                    }
                });
        }
    };

    that.removeCouponCode = function () {
        // couponcode only used in CheckoutCart = [1]
        $.getJSON("/api/checkout/removecouponcode", { couponCode: that.shoppingCartArray()[1].couponCode() })   // couponcode only used in CheckoutCart = [1]
            .done(function () {
                that.refresh();
            });
    };

    that.updateEnableBuyButton = function () {
        // enableBuyButton only used in CheckoutCart = [1]
        if ($("#login").val() != "True") {
            that.enableBuyButton('disabled');
            return true;
        }

        if (that.acceptTerms() && that.shoppingCartArray()[1].total() === "0" && that.shoppingCartArray()[1].cartItems !== undefined && that.shoppingCartArray()[1].cartItems().length > 0
            && (that.shoppingCartArray()[2].cartItems === undefined || that.shoppingCartArray()[2].cartItems().length === 0)) {
            that.enableBuyButton(undefined);
            return true;
        }

        if (that.acceptTerms() && that.paymentType() !== undefined && that.shoppingCartArray()[1].cartItems !== undefined && that.shoppingCartArray()[1].cartItems().length > 0
            && (that.shoppingCartArray()[2].cartItems === undefined || that.shoppingCartArray()[2].cartItems().length === 0))
            that.enableBuyButton(undefined);
        else
            that.enableBuyButton('disabled');

        return true;
    };

    for (var i = 1; i < 101; i++) {
        that.availableQuantities.push(i);
    };

    that.checkout = function () {
        // checkout only used in shopping cart DefaultCart = [0]
        var products = [];
        $.each(that.shoppingCartArray()[0].cartItems(), function (index, item) {
            products.push({
                'name': (item.designation() != null ? item.designation() : item.displayName()),
                'code': item.code(),
                'price': (item.discountedUnitPrice() == null ? item.unitPrice() : item.discountedUnitPrice()),
                'category': item.category(),
                'variant': GetDeliveryTypeByCode(item.code()),
                'quantity': item.quantity()
            });
        });

        var countquest = new Countquest();
        countquest.checkOut(1, products);

        // Due to firefox (and possibly other future browsers) shimming of GA or completely AD blocking it, we do the redirect after some time to allow countquest to finish instead of a callback
        setTimeout(function () {
            document.location = that.shoppingCartArray()[0].checkoutPageUrl();
        }, 250);
    };

    that.purchase = function () {
        // purchase only used in CheckoutCart = [1]
        var transaction = {
            'id': that.shoppingCartArray()[1].orderId(),
            'revenue': that.shoppingCartArray()[1].sum(),
            'tax': that.shoppingCartArray()[1].vat(),
            'shipping': that.shoppingCartArray()[1].shippingTotal(),
            'coupon': that.shoppingCartArray()[1].couponCode()
        }

        var products = [];
        $.each(that.shoppingCartArray()[1].cartItems(), function (index, item) {
            products.push({
                'name': (item.designation() != null ? item.designation() : item.displayName()),
                'code': item.code(),
                'price': (item.discountedUnitPrice() == null ? item.unitPrice() : item.discountedUnitPrice()),
                'category': item.category(),
                'variant': GetDeliveryTypeByCode(item.code()),
                'quantity': item.quantity()
            });
        });

        var countquest = new Countquest();
        return countquest.purchase(transaction, products);
    };

    that.moveToDefault = function () {
        $.getJSON("/api/cart/movetocheckoutcart", function () {
            that.refresh();
        });
    };

    that.moveToSubscription = function () {
        $.getJSON("/api/cart/movetosubscriptioncart", function (result) {
            if (result.data !== "ok") {
                alert(result.data);
            }
            that.refresh();
        });
    };

    that.finalizeAddToSubscription = function () {
        that.addToSubscriptionButtonDisabled(true);
        var numberOfCustomPackageProducts = that.customPackagesCount();
        var comment = $("#subscriptioncomment").val();
        that.subscriptionIteamAdded = that.shoppingCartArray()[2];
        $.getJSON("/api/cart/addtosubscription", { comment: comment, numberOfCustomPackageProducts: numberOfCustomPackageProducts })
            .done(function (result) {
                if (result.data.success) {
                    that.subscriptionCheckoutThanks(true);
                } else {
                    alert(result.data.message);
                }
                that.refresh();
            });
    };

    that.refresh();
};

var shoppingCart = new ShoppingCart();
if (document.getElementById("shopping-cart") !== null)
    ko.applyBindings(shoppingCart, document.getElementById("shopping-cart"));

if (document.getElementById("checkout-page") !== null)
    ko.applyBindings(shoppingCart, document.getElementById("checkout-page"));

function AddToCartClick(code, invalid, includestandardamendmentscorrections, addSingleCopy) {
    ko.contextFor(document.getElementById("shopping-cart")).$data.addToCart(code, includestandardamendmentscorrections, addSingleCopy);

    if (invalid === "True" || invalid === true) {
        $("#invalid-product-modal").modal();
    }
    $("#shopping-cart").collapse("show");
    $("#shopping-cart-button").focus();
    $("HTML, BODY").animate({
        scrollTop: 0
    }, 300);
}

$(function () {
    $(".addtocart:not(.singlecopy)").click(function () {
        AddToCartClick($(this).data("code"), $(this).data("invalid"), true, false);
    });
    $(".addtocart.singlecopy").click(function () {
        AddToCartClick($(this).data("code"), $(this).data("invalid"), true, true);
    });
});

function GetDeliveryTypeByCode(code) {
    if (code == null)
        return "";

    var splitted = code.split("-");

    if (splitted.length > 2)
        return splitted[2];

    return "";
};

$("#shopping-cart-button").click(function () {
    var shoppingCart = $('#shopping-cart');
    var menuHeight = $('#my-sis-bar-desktop').height() + $('.navbar-header').height();
    if ($(window).scrollTop() > menuHeight && shoppingCart.is(':visible')) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return false;
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});


